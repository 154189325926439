import { EnergyVector } from 'prosumer-app/+scenario';
import { Utils } from 'prosumer-app/core/utils';
import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ScenarioDetailState } from '../scenario-detail';
import { EnergyVectorInfo } from './energy-vector.state';
import { EnergyVectorStore } from './energy-vector.store';

@Injectable({ providedIn: 'root' })
export class EnergyVectorQuery extends QueryEntity<
  ScenarioDetailState<EnergyVectorInfo>
> {
  constructor(
    public store: EnergyVectorStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  energyVectorInfos$ = this.keeper.selectActive().pipe(
    switchMap(({ scenario }) =>
      this.selectAll({
        filterBy: (entity) => entity.scenarioUuid === scenario,
      }),
    ),
  );

  energyVectors$ = this.energyVectorInfos$.pipe(
    map((evInfo) => this.mapInfoToEnergyVector(evInfo)),
  );

  allEnergyVectors$ = this.selectAll().pipe(
    map((evInfo) => this.mapInfoToEnergyVector(evInfo)),
  );

  mapInfoToEnergyVector(vectors: EnergyVectorInfo[]): EnergyVector[] {
    return Utils.resolveToEmptyArray(vectors).map(
      (vector: EnergyVectorInfo) => ({
        selected: true,
        custom: vector.isCustom,
        type: vector.vectorType,
        name: vector.name,
        value: vector.energyVectorId,
      }),
    );
  }

  selectActiveVectors(): Observable<EnergyVector[]> {
    return this.energyVectors$;
  }

  selectAllForActiveScenario(): Observable<EnergyVectorInfo[]> {
    return this.selectAll().pipe(
      map((entities) => this.onlyForActiveScenario(entities)),
    );
  }

  selectActiveVectorIds(): Observable<string[]> {
    return this.selectActiveVectors().pipe(
      map((vectors) => this.vectorsToValues(vectors)),
    );
  }

  translate(energyVectorId: string): string {
    return this.getViaEnergyVectorId(energyVectorId).name;
  }

  getVectorOptions(): FormFieldOption<string>[] {
    return this.mapInfoToEnergyVector(this.getActiveVectors());
  }

  private getActiveVectors(): EnergyVectorInfo[] {
    return Utils.resolveToEmptyArray(
      this.getAll().filter(
        (vector) => vector.scenarioUuid === this.getActiveScenario(),
      ),
    );
  }

  private getActiveScenario(): string {
    return this.keeper.getActiveEntities().scenario;
  }

  private getViaEnergyVectorId(id: string): EnergyVectorInfo {
    return Utils.resolveToEmptyObject(
      this.getAll().find((vector) => vector.energyVectorId === id),
    );
  }

  private vectorsToValues(vectors: EnergyVector[]): string[] {
    return Utils.resolveToEmptyArray(vectors).map((vector) => vector.value);
  }

  private onlyForActiveScenario(
    entities: EnergyVectorInfo[],
  ): EnergyVectorInfo[] {
    return entities.filter((entity) => this.isFromActiveScenario(entity));
  }

  private isFromActiveScenario(entity: EnergyVectorInfo): boolean {
    return entity.scenarioUuid === this.keeper.getActiveScenario();
  }
}
